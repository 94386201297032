import { defineStore } from "pinia"
import { useStorage } from "@vueuse/core"

export const useUserStore = defineStore( "UserStore", {

  id: 'user',
  state: () => { 
    return {
      data: useStorage('user-data', {}),
      profile: useStorage('user-profile', {}),
      permissions: useStorage('user-permissions', []),
      token: useStorage('user-auth-token', "")
    }
  },
  // options
  // getters
  actions: {
    clear() {
      this.data = {}
      this.profile = {}
      this.permissions = []
      this.token = ""
    }
  },
  getters: {
    authenticated() {
      return ( this.token != "" )
    },
    firstName() {
        return this.data.first_name || ""
    },
    lastName() {
        return this.data.last_name || ""
    },
    fullName() {
        return this.data.first_name  + " " + this.data.last_name
    }
  }
})
