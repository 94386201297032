import { createRouter, createWebHistory } from 'vue-router'
import Dashboard from '../views/Dashboard.vue'
import MissionList from '../views/MissionList.vue'
import MissionNew from '../views/MissionNew.vue'
import MissionEdit from '../views/MissionEdit.vue'
import MissionCopy from '../views/MissionCopy.vue'
import MissionDetail from '../views/MissionDetail.vue'
import MembersList from '../views/MembersList.vue'
import Settings from '../views/Settings.vue'
import ProfileSettings from '../views/ProfileSettings.vue'
import RoleSettings from '../views/RoleSettings.vue'
import PasswordSettings from '../views/PasswordSettings.vue'
import Profile from '../views/Profile.vue'
import Login from '../views/Login.vue'
import Logout from '../views/Logout.vue'

import { useUserStore } from '@/stores/user'

const routes = [
  {
    path: '/',
    name: 'Dashboard',
    component: Dashboard,
    meta: {
      requiresAuth: true,
      toolbarTitle: 'Dashboard'
    }
  },
  {
    path: '/missions',
    name: 'Missions',
    component: MissionList,
    meta: {
      requiresAuth: true,
      toolbarTitle: 'Wachplan'
    }
  },
  {
    path: '/missions/new',
    name: 'MissionNew',
    component: MissionNew,
    meta: {
      requiresAuth: true,
      toolbarType: 'navback',
      toolbarTitle: 'Termin erstellen'
    }
  },
  {
    path: '/missions/shift',
    name: 'MissionShift',
    component: MissionList,
    meta: {
      requiresAuth: true,
      toolbarType: 'cancel',
      toolbarTitle: 'Termin wählen',
      hideFilters: true,
      showCancel: true,
      filterIndex: 3
    }
  },
  {
    path: '/missions/:id',
    name: 'MissionDetail',
    component: MissionDetail,
    meta: {
      requiresAuth: true,
      toolbarType: 'navback',
      toolbarTitle: ''
    }
  },
  {
    path: '/missions/:id/edit',
    name: 'MissionEdit',
    component: MissionEdit,
    meta: {
      requiresAuth: true,
      toolbarType: 'navback',
      toolbarTitle: 'Termin bearbeiten'
    }
  },
  {
    path: '/missions/:id/copy',
    name: 'MissionCopy',
    component: MissionCopy,
    meta: {
      requiresAuth: true,
      toolbarType: 'navback',
      toolbarTitle: 'Termin kopieren'
    }
  },
  {
    path: '/members',
    name: 'Members',
    component: MembersList,
    meta: {
      requiresAuth: true,
      toolbarTitle: 'Mitglieder'
    }
  },
  {
    path: '/settings/',
    name: 'Settings',
    component: Settings,
    meta: {
      requiresAuth: true,
      toolbarTitle: 'Einstellungen'
    }
  },
  {
    path: '/settings/profile',
    name: 'ProfileSettings',
    component: ProfileSettings,
    meta: {
      requiresAuth: true,
      toolbarType: 'navback',
      toolbarTitle: 'Profil bearbeiten'
    }
  },
  {
    path: '/settings/roles',
    name: 'RoleSettings',
    component: RoleSettings,
    meta: {
      requiresAuth: true,
      toolbarType: 'navback',
      toolbarTitle: 'Rollen verwalten'
    }
  },
  {
    path: '/settings/password',
    name: 'PasswordSettings',
    component: PasswordSettings,
    meta: {
      requiresAuth: true,
      toolbarType: 'navback',
      toolbarTitle: 'Passwort ändern'
    }
  },
  {
    path: '/profiles/:id',
    name: 'Profiles',
    component: Profile,
    meta: {
      toolbarType: 'navback',
      requiresAuth: true,
      toolbarTitle: ''
    }
  },
  {
    path: '/login',
    name: 'Login',
    component: Login,
    meta: { requiresAuth: false }
  },
  {
    path: '/logout',
    name: 'Logout',
    component: Logout
  },
]

const router = createRouter({
  mode: 'history',
  history: createWebHistory(import.meta.env.BASE_URL),
  routes
})

router.beforeEach((to) => {
  const userStore = useUserStore()
  if( to.meta.requiresAuth && !userStore.authenticated ){
    return {name: 'Login'}
  }
  if( to.name === "Login" && userStore.authenticated ) {
    console.log("Login guard triggered")
    return false
  }
})
export default router
