<template>
  <q-page padding>
    <div
        v-if="loadingMission"
        class="col items-center justify-center q-pa-lg q-gutter-md"
        >
        <div class="row justify-center">
          <q-spinner size="3em"  color="primary" />
        </div>
    </div>
    <q-form
        v-else
        class="col q-gutter-md"
        >
        <q-input
            label="Name *"
            :rules="[val => !!val || 'Field is required']"
            v-model="mission.title"
            />
      <q-input
          label="Kurzbeschreibung"
          v-model="mission.excerpt"
          />
        <div>
          <div class="text-h6">Beschreibung</div>
          <q-editor
              filled
              placeholder="Füge hier den Beschreibungstext ein..."
              type="textarea"
              label="Beschreibung *"
              :rules="[val => !!val || 'Field is required']"
              v-model="mission.description"
              />
        </div>
        <div class="col-6 col-md-4">
          <q-input
              hint="Startdatum *"
              type="date"
              :rules="[val => !!val || 'Field is required']"
              v-model="mission.start_date"
              />
        </div>
        <div class="col-6 col-md-4">
          <q-input
              hint="Enddatum *"
              type="date"
              :rules="[val => !!val || 'Field is required']"
              v-model="mission.end_date"
              />
        </div>
      <q-btn
          color="primary"
          label="Änderungen speichern"
          icon="save"
          @click="update"
          />
    </q-form>
  </q-page>
</template>

<script>
  import { getMission, updateMission } from '@/services/Missions.js'
  import { Notify } from 'quasar'

  export default {
    data() { 
      return {
        mission: {
          title: "",
          excerpt: "",
          description: "",
          start_date: "",
          end_date: "",
          team_set: []
        },
        loadingMission: true
      } 
    },
    mounted() {
      getMission( this.$route.params.id )
        .then( response => {
          this.mission = response
          this.loadingMission = false
        })
    },
    methods: {
      update() {
        updateMission( this.mission, this.$route.params.id )
          .then( () => {
            Notify.create( {
              message: "Änderungen gespeichert",
              type: "positive"
            } )
            this.$router.back() 
          })
      }
    }
  }
</script>
