
import 'quasar/src/css/index.sass'
import './quasar.sass'
import lang from 'quasar/lang/en-US.js'
import '@quasar/extras/roboto-font/roboto-font.css'
import '@quasar/extras/material-icons/material-icons.css'
import { Notify } from 'quasar'
import { Dialog } from 'quasar'

// To be used on app.use(Quasar, { ... })
// brand colors based on https://www.color-hex.com/color-palette/1294
export default {
  config: {
    brand: {
    primary: '#005b96',
    secondary: '#011f4b',
    accent: '#005b96',
    dark: '#101c30',
    'dark-page': '#010c1c',
    positive: '#21BA45',
    negative: '#C10015',
    info: '#6497b1',
    warning: '#F2C037'
    },
    dark: true
  },
  plugins: {
    Notify,
    Dialog
  },
  lang: lang
}
