<template>
  <q-page padding>
    <div>
      <q-toolbar>
        <q-toolbar-title class="text-h4">{{ mission.title }}
        <q-badge 
          v-if="mission.is_canceled"
          color="negative"
          align="top"
          >abgesagt</q-badge>
        </q-toolbar-title>
      </q-toolbar>
      <q-toolbar>
      <q-toolbar-title>
        <span><q-icon name="event" /> {{ start_date }} </span>
        <span v-if="start_date != end_date"> - {{ end_date }}</span>
        </q-toolbar-title>
        <q-btn
        fab-mini
        flat
        icon="edit"
        v-if="canEditTeam"
        @click="$router.push('/missions/' + mission.id + '/edit')"
        />
        <q-btn-dropdown
        fab-mini
        auto-close
        v-if="canEditTeam"
        dropdown-icon="more_vert"
        unelevated
        >
          <q-list>
            <q-item
            clickable
            @click="createTeam"
            >
              <q-item-section avatar>
                <q-icon name="group_add"/>
              </q-item-section>
              <q-item-section>Neues Team
              </q-item-section>
            </q-item>
            <q-item
            clickable
            @click="$router.push('/missions/' + mission.id + '/copy')"
            >
            <q-item-section avatar>
              <q-icon name="copy_all"/>
            </q-item-section>
              <q-item-section>Termin kopieren
              </q-item-section>
            </q-item>
            <q-item
            clickable
            @click="reopenMission"
            v-if="mission.is_canceled"
            >
              <q-item-section avatar>
                <q-icon name="free_cancellation"/>
              </q-item-section>
              <q-item-section>Absage zurückziehen
              </q-item-section>
            </q-item>
            <q-item
            clickable
            @click="cancelMission"
            v-else
            >
              <q-item-section avatar>
                <q-icon name="event_busy"/>
              </q-item-section>
              <q-item-section>Termin absagen
              </q-item-section>
            </q-item>
            <q-item
            clickable
            @click="deleteMission"
            >
              <q-item-section avatar>
                <q-icon name="delete"/>
              </q-item-section>
              <q-item-section>Termin löschen
              </q-item-section>
            </q-item>
          </q-list>
        </q-btn-dropdown>
      </q-toolbar>
    </div>
    <div class="row justify-center">
      <div class="col-12" v-if="mission.description">
        <q-card :flat="$q.dark.mode" class="q-pa-md" >
          <q-card-section v-html="mission.description" />
        </q-card>
      </div>
      <div class="col-12"> 
          <div class="text-h5 q-mt-xl q-mb-md">Teams</div>
          <div class="row q-col-gutter-md">
          <template v-for="team in teams" :key="team">
          <div class="col-12 col-md-5">
          <team :team="team" :locked="mission.is_canceled" @team-removed="removeTeam"/>
          </div>
        </template>
        </div>
      </div>
    </div>
  </q-page>
</template>


<script>
import { getMission, updateMission, cancelMission, reopenMission, deleteMission } from '@/services/Missions.js'
import { getTeamsByMission, createTeam } from '@/services/Teams.js'
import { hasPermission } from '@/services/Users.js'
//import { getTeamsByMission, createTeam } from '@/services/Teams.js'
//import { showInfo } from '@/services/Application.js'
import Team from '@/components/Team.vue'
import { Dialog } from 'quasar'

export default {
  components: {
    'team' : Team
  },
  data() {
    return {
      mission: {},
      teams: [],
      date_options: {
        day:'2-digit',
        month:'2-digit',
        year:'numeric'
      }
    }
  },
  mounted() {
    this.loadMission()
    this.loadTeams()
  },
  computed: {
    start_date() {
      let start = new Date(this.mission.start_date)
      return start.toLocaleDateString(undefined,this.date_options)
    },
    end_date() {
      let end = new Date(this.mission.end_date)
      return end.toLocaleDateString(undefined,this.date_options)
    },
    canEditTeam() {
      return hasPermission("missions.change_team")
    },
    canAddTeam() {
      return hasPermission("missions.add_team")
    }
  },
  methods: {
    createTeam() {
      Dialog.create({
        title: "Team erstellen",
        message: "Welchen Namen soll das Team bekommen?",
        prompt: {
          model: "",
          type: "text",
          isValid: val => val.length > 0
        },
        persistent: false
      })
        .onOk( name => {
          createTeam(name, this.mission.id)
            .then( response => this.teams.push( response ))
        })
    },
    removeTeam(id) {
      this.teams = this.teams.filter( elem => elem.id != id )
    },
    loadMission() {
      getMission(this.$route.params.id)
      .then( response => { this.mission = response } )
    },
    loadTeams() {
      getTeamsByMission( this.$route.params.id )
      .then( response => { this.teams = response })
    },
    updateMission(mission) {
        updateMission(mission, this.mission.id)
        .then(this.loadMission())
    },
    deleteMission() {
      Dialog.create({
        title: "Termin löschen",
        message: "Möchtest du den Termin wirklich löschen?",
        ok: {
          push: true,
          color: "negative"
        },
        cancel: true,
        persistent: true
      })
        .onOk( ()=>{
          deleteMission(this.mission.id)
            .then(this.$router.push('/missions'))
        })
    },
    cancelMission() {
      Dialog.create({
        title: "Termin absagen",
        message: "Möchtest du den Termin wirklich absagen?",
        ok: {
          push: true,
          color: "negative"
        },
        cancel: true,
        persistent: true
      })
        .onOk(() =>{ cancelMission(this.mission.id)
            .then( response => this.mission = response )
        })
    },
    reopenMission() {
      Dialog.create({
        title: "Absage zurückziehen",
        message: "Möchtest du die Absage wirklich zurückziehen?",
        ok: {
          push: true,
          color: "negative"
        },
        cancel: true,
        persistent: true
      })
        .onOk(() =>{ reopenMission(this.mission.id)
            .then( response => this.mission = response )
        })
    }
  }
}
</script>

<style>
</style>
