<template>
  <q-page> 
    <div class="row justify-center q-gutter-md q-pt-md">
      <q-card :flat="$q.dark.mode" class="col-10 col-md-3">
              <q-img :src="profile.avatar">
            <div class="absolute-bottom text-h5">
              {{ user.first_name }} {{ user.last_name }}
            </div>
              </q-img>
          <q-card-section>
            <span class="text-h6">Kontakt</span>
            <q-list>
              <q-item>
                <q-item-section avatar>
                  <q-icon name="house" /> 
                </q-item-section>
                <q-item-section>
                  {{ profile.address }}
                </q-item-section>
              </q-item>
              <q-item>
                <q-item-section avatar>
                  <q-icon name="phone" /> 
                </q-item-section>
                <q-item-section>
                  <a class="text-primary" :href="`tel:${ profile.phone }`">{{ profile.phone }}</a>
                </q-item-section>
              </q-item>
            </q-list>
          </q-card-section>
        </q-card>
      <q-card
        :flat="$q.dark.mode" 
        class="q-card col-10 col-md-3 q-pa-sm"
        v-if="missions.length"
        >
          <q-card-section>
            <span class="text-h6">Wachdienste ({{ missions.length }})</span>
          <q-list separator>
            <template v-for="mission in missions" :key="mission">
              <mission-list-item :mission="mission"
                :details="false"
                :badges="false"
                />
            </template>
          </q-list>
        </q-card-section>
      </q-card>
    </div>
  </q-page> 
</template>

<script>
import { getUser, getProfile } from '@/services/Users.js'
import { getMissionsByYearAndMember } from '@/services/Missions.js'
import MissionListItem from '@/components/MissionListItem.vue'

export default {
  components: { "mission-list-item": MissionListItem },
  data() {
    return {
      user: {},
      missions: [],
      profile: {} 
    }
  },
  mounted() {
    this.update()
  },
  methods: {
    by_date(a, b){
      return new Date(a.start_date).valueOf() - new Date(b.start_date).valueOf()
    },
    update() {
      getUser( this.$route.params.id )
      .then( response => { 
        this.user = response
        })
      getProfile( this.$route.params.id )
      .then( response => { 
        this.profile = response
        })
      getMissionsByYearAndMember(new Date().getFullYear(), this.$route.params.id)
        .then( data => {
          this.missions = data.sort(this.by_date)
      })
    }
  }
}
</script>
