<template>
<q-item>
  <q-item-section avatar> 
    <ProfileAvatar v-if="pos.member" :id="pos.member.id" />
    <q-avatar v-else icon="help_outline" />
  </q-item-section>
  <q-item-section>
    <template v-if="pos.member">
      <q-item-label :to="`/profiles/${pos.member.id}`">{{pos.member.name}}</q-item-label>
      <q-item-label caption lines="1" >{{role_name}}</q-item-label>
    </template>
    <template v-else>
      <q-item-label>{{role_name}}</q-item-label>
    </template>
  </q-item-section>
  <template v-if="!locked">
    <q-item-section side v-if="pos.offered">
      <q-btn flat round dense
        icon="swap_horiz"  class="bg-transparent" >
        <q-tooltip>Zum Tausch angeboten</q-tooltip>
      </q-btn>
    </q-item-section>
    <q-item-section side v-if="can_offer">
      <q-btn flat round dense 
        icon="swap_horiz"  class="bg-primary text-white" @click="offer">
        <q-tooltip>Zum Tausch anbieten</q-tooltip>
      </q-btn>
    </q-item-section>
    <q-item-section side v-if="can_shift">
      <q-btn flat round dense 
        icon="update"  class="bg-primary text-white" @click="start_shift">
        <q-tooltip>Dienst verschieben</q-tooltip>
      </q-btn>
    </q-item-section>
    <q-item-section side v-if="can_assign && shift">
      <q-btn flat round dense 
        icon="update"  class="bg-positive text-white"  @click="assign_shift">
        <q-tooltip>Position besetzen</q-tooltip>
      </q-btn>
    </q-item-section>
    <q-item-section side v-if="can_assign && !shift">
      <q-btn flat round dense 
        icon="person"  class="bg-primary text-white"  @click="assign">
        <q-tooltip>Position besetzen</q-tooltip>
      </q-btn>
    </q-item-section>
    <q-item-section side v-if="can_dismiss">
      <q-btn flat round dense
        icon="clear"  class="bg-red text-white" @click="dismiss">
        <q-tooltip>Position freigeben</q-tooltip>
      </q-btn>
    </q-item-section>
  </template>
  
</q-item>
</template>

<script>
import {
  getPosition, getPositionActions,
  assignPosition, dismissPosition,
  offerPosition, shiftPosition
} from '@/services/Teams.js'

import { useAppStore } from '@/stores/application.js'
import ProfileAvatar from '@/components/ProfileAvatar.vue'
import { Dialog, Notify } from 'quasar'

export default {
  components: {
    ProfileAvatar
  },
  props: { position: Object, locked: Boolean },
  setup() {
    const appStore = useAppStore()
    return { appStore }
  },
  data() {
    return {
      pos: this.position,
      actions: [],
      shift: false
    }
  },
  computed: {
    can_assign() {
      return this.actions.includes("assign")
    },
    can_dismiss() {
      return this.actions.includes("dismiss")
    },
    can_offer() {
      return this.actions.includes("offer")
    },
    can_shift() {
      return this.actions.includes("shift")
    },
    role_name() {
      if (this.pos.optional)
        return this.pos.role.name + " (optional)"
      else
        return this.pos.role.name
    }
  },
  mounted() {
    this.getActions()
    this.shift = (this.appStore.action == "shift-position")
  },
  methods: {
    assign() {
      Dialog.create({
        title: "Position besetzen",
        message: `Möchtest du die Position '${this.role_name}' wirklich besetzen? Das Eintragen im Wachplan ist verbindlich und ein Austragen nur aus wichtigen Gründen möglich.`,
        ok: {
          push: true,
          color: "positive"
        },
        cancel: true,
        persistent: true
      })
        .onOk( ()=>{
          assignPosition(this.position.id)
            .then( () => {
              this.update()
            })
        })
    },
    dismiss() {
      Dialog.create({
        title: "Position freigeben",
        message: 'Möchtest du die Position wirklich freigeben?',
        ok: {
          push: true,
          color: "negative"
        },
        cancel: true,
        persistent: true
      })
        .onOk( ()=>{
          dismissPosition(this.position.id)
            .then(() => {
              this.update()
            })
        }) 
    },
    offer() {
      offerPosition(this.position.id)
        .then(() => {
            this.update()
            })
    },
    start_shift() { 
      console.log("Invoke shift position")
      this.appStore.action_state.action = "shift-position"
      this.appStore.action_state.action_item.shift_from_id = this.position.id
      this.$router.push("/missions/shift")
    },
    assign_shift() { 
      console.log("Shift position")
      shiftPosition(this.appStore.actionItem.shift_from_id,this.position.id)
        .then(() => {
            Notify.create({
                message: "Dienst verschoben",
                type: "positive"
            })
          this.appStore.clearAction()
          this.appStore.clearMissionData()
          this.$router.replace("/missions")
        })
    },
    getActions() {
        getPositionActions(this.position.id)
        .then((response) => { this.actions = response })
    },
    update() {
        getPosition(this.position.id)
        .then((response) => { this.pos = response })
        this.getActions()
    }
  }
}
</script>
