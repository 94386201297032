<template>
<q-list>
  <q-item 
  v-for="role in rolesAll"
  :key="role"
  >
    <q-item-section>
      <div>{{ role.name }}</div>
    </q-item-section>
    <q-item-section
    side right>
      <q-toggle 
      v-model="rolesSelected"
      :val="role.name"
      @update:model-value="updateRoles"
      />
    </q-item-section>
  </q-item>
</q-list>
</template>

<script>
import { useUserStore } from '@/stores/user.js'
import { getRoles, getMemberRoles, setMemberRoles } from '@/services/Users.js'
import { ref } from 'vue'

export default {
  setup() {
    const userStore = useUserStore()
    return { userStore }
  },
  data() {
    return {
      rolesSelected: ref([]),
      rolesAll: []
    }
  },
  mounted() {
    getMemberRoles(this.userStore.data.id).then( (roles) => {
      this.rolesSelected = roles.map( el => el.name ) 
      })
    getRoles().then( (roles) => {
      this.rolesAll = roles.sort((a, b) => a.name.localeCompare(b.name))
      })
  },
  methods: {
    updateRoles() {
      let roles = this.rolesAll
      .filter( el => this.rolesSelected.includes( el.name ))
      .map( el => el.id )
      setMemberRoles( this.userStore.data.id, roles )
      .then( () => console.log("update success"))
      .catch( () => console.log("update failed"))
    }
  }
}
</script>
