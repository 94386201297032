<template>
  <q-card padding class="q-mb-md" :flat="$q.dark.mode">
    <template v-if="!edit">
      <q-card-section>
    <q-toolbar>
      <q-toolbar-title>
          {{ team.name }}
      </q-toolbar-title>
        <q-btn flat round dense 
        icon="edit"
        v-if="canEditTeam"
        @click="edit = true"/>
    </q-toolbar>
        <q-list separator>
            <TeamPosition v-for="position in positions" :position="position" :locked="locked" />
        </q-list>
      </q-card-section>
    </template>
    <template v-else>
      <q-card-section>
    <q-toolbar>
      <q-toolbar-title>
          {{ team.name }}
      </q-toolbar-title>
        <q-btn flat round dense 
        icon="person_add"
        v-if="canAddTeamPosition"
        @click="addPosition"/>&nbsp;
        <q-btn flat round dense 
        icon="delete"
        v-if="canDeleteTeam"
        @click="confirm = true"/>&nbsp;
        <q-btn flat round icon="done"
        @click="edit = false"/>
    </q-toolbar>
        <q-list separator>
          <TeamPositionEdit
          v-for="position in positions"
          :position="position"
          :roles="roles"
          @remove-position="removePosition"
          @update-position="updatePosition"
          />
        </q-list>
      </q-card-section>
  </template>
  </q-card>
  <q-dialog v-model="confirm" persistent>
  <q-card :flat="$q.dark.mode">
    <q-card-section class="row items-center">
      <q-avatar icon="delete" color="red" text-color="white" />
      <span class="q-ml-sm">Team {{ team.name }} wirklich löschen?</span>
    </q-card-section>

    <q-card-actions align="right">
      <q-btn flat label="Abbrechen" color="primary" v-close-popup />
      <q-btn flat label="Löschen" color="red" @click="deleteTeam" v-close-popup />
    </q-card-actions>
  </q-card>
</q-dialog>
</template>

<script>
import TeamPosition from '@/components/TeamPosition.vue'
import TeamPositionEdit from '@/components/TeamPositionEdit.vue'

import { getRoles, hasPermission } from '@/services/Users.js'
import {
  deleteTeam,
  getPosition,
  createPosition,
  updatePosition,
  deletePosition } from '@/services/Teams.js'

import { ref } from 'vue'

export default {
  components: {
    TeamPosition,
    TeamPositionEdit
  },
  props: { 
    team: Object,
    locked: Boolean },
  emits: ['teamRemoved'],
  data() {
    return {
      edit: false,
      positions: [],
      roles: [],
      confirm: ref(false)
    }
  },
  mounted() {
    this.positions = this.$props.team.positions
    getRoles().then( roles => this.roles = roles )
  },
  computed: {
    canEditTeam() {
      return hasPermission("missions.change_team")
    },
    canAddTeamPosition() {
      return hasPermission("missions.add_teamposition")
    },
    canDeleteTeam() {
      return hasPermission("missions.delete_team")
    },
  },
  methods: {
    addPosition() {
      createPosition(
        this.team.id, 
        this.roles[0].id
        ).then( newPosition => {
          getPosition(newPosition.id)
          .then(position => this.positions.push(position)
          )
        })
    },
    deleteTeam() {
      deleteTeam(this.team.id)
      .then( () => this.$emit('teamRemoved', this.team.id) )
    },
    removePosition(id) {
      deletePosition(id)
      .then( () => this.positions = this.positions.filter( elem => elem.id != id ))
    },
    updatePosition(pos) {
      pos.team = this.team.id
      updatePosition(pos)
      .then( () => {
          getPosition(pos.id).then( response => {
              let index = this.positions.findIndex( e => e.id === pos.id )
              this.positions[index] = response
              })
          })
    }
  }
}
</script>
