<template>
  <q-page padding>
    <PasswordChangeForm />
  </q-page>
</template>

<script>
import PasswordChangeForm from '@/components/PasswordChangeForm.vue'

export default {
  components: { PasswordChangeForm }
}
</script>
