<template>
  <q-page padding>
    <q-list>
      <q-item-label header>Persönliche Einstellungen</q-item-label>
      <menu-item icon="face" route="/settings/profile" >Profil bearbeiten</menu-item>
      <menu-item icon="support" route="/settings/roles" >Rollen verwalten</menu-item>
      <menu-item icon="key" route="/settings/password" >Passwort ändern</menu-item>
      <q-item-label header>Darstellung</q-item-label>
      <dark-mode-item>Dunkles Design</dark-mode-item>
    </q-list>
  </q-page>
</template>


<script>
import MenuListItem from '@/components/MenuListItem.vue'
import DarkModeToggle from '@/components/DarkModeToggle.vue'

export default {
  components: {
    'menu-item' : MenuListItem,
    'dark-mode-item' : DarkModeToggle
  }
}
</script>
