<template>
<q-list separator >
  <q-item>
    <q-item-section avatar><q-icon name="water"/></q-item-section>
    <q-item-section>Wassertemperatur</q-item-section>
    <q-item-section avatar v-if="sensor">{{ sensor.dev_value_2 }} °C</q-item-section>
    <q-item-section avatar v-else><q-spinner /></q-item-section>
  </q-item>
  <q-item>
    <q-item-section avatar><q-icon name="thermostat"/></q-item-section>
    <q-item-section>Lufttemperatur</q-item-section>
    <q-item-section avatar v-if="sensor">{{ sensor.dev_value_1 }} °C</q-item-section>
    <q-item-section avatar v-else><q-spinner /></q-item-section>
  </q-item>
  <q-item>
    <q-item-section avatar><q-icon name="water_drop"/></q-item-section>
    <q-item-section>Luftfeuchtigkeit</q-item-section>
    <q-item-section avatar v-if="sensor">{{ sensor.dev_value_3 }} %</q-item-section>
    <q-item-section avatar v-else><q-spinner /></q-item-section>
  </q-item>
</q-list>
</template>

<script>
import { getSensorData } from '@/services/Sensors.js'

export default {
  props: {
    sensorId: String
  }, 
  data() {
    return {
      sensor: null,
      interval: null
      }
  },
  mounted() {
    this.update()
    this.interval = setInterval( this.update, 1000 * 60)
  },
  unmounted() {
    clearInterval(this.interval)
  },
  methods: {
    update() {
      getSensorData(this.sensorId).then(
        data => {
          this.sensor = data 
        })
    }
  }
}
</script>
