<template>
    <q-page id="login" class="transparent">
      <q-card class="fixed-center">
        <div class="row justify-center">
          <q-img src="@/assets/images/textlogo.png" width="85%" class="q-mt-md" />
        </div>
      <login-form id="login-form" />
      </q-card>
    </q-page>
</template>

<script>
import LoginForm from '@/components/LoginForm.vue'

export default {
  components: {
    'login-form': LoginForm
  },
  mounted() {
    this.$q.dark.set(false)
  }
}
</script>

<style scoped>
#login {
  background-image: url('@/assets/images/login-background.jpg');
  background-size: cover;
}
#login-form {
  width: 25rem;
}
</style>
