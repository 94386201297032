<script setup lang="ts">
import { useRegisterSW } from 'virtual:pwa-register/vue'

const {
  offlineReady,
  needRefresh,
  updateServiceWorker,
} = useRegisterSW()

async function close() {
  offlineReady.value = false
  needRefresh.value = false
}
</script>

<template>
  <q-banner
    v-if="offlineReady || needRefresh"
    class="pwa-toast bg-warning"
    role="alert"
  >
    <div class="message q-my-md">
      <span v-if="offlineReady">
        Du kannst die App jetzt nutzen. Viel Spaß! :-)
      </span>
      <span v-else>
        Ein Update ist verfügbar. Bitte neu laden.
      </span>
    </div>
    <q-btn v-if="needRefresh" label="Update" @click="updateServiceWorker()" />
  </q-banner>
</template>

<style>
/*
.pwa-toast {
    position: fixed;
  right: 0;
  bottom: 0;
  margin: 16px;
  padding: 12px;
  border: 1px solid #8885;
  border-radius: 4px;
  z-index: 1;
  text-align: left;
  box-shadow: 3px 4px 5px 0 #8885;
  background-color: white;
}
.pwa-toast .message {
  margin-bottom: 8px;
}
.pwa-toast button {
  border: 1px solid #8885;
  outline: none;
  margin-right: 5px;
  border-radius: 2px;
  padding: 3px 10px;
}*/
</style>
