import { appAPI } from '@/api'


export async function createTeam( name, mission_id)
{ 
  const newTeam = { name: name, mission: mission_id, positions: [] }
  const response = await appAPI.post("/teams/", newTeam);
  return response.data;
}

export async function getTeam(id)
{
  const response = await appAPI.get(`/teams/${id}/`);
  return response.data;
}

export async function deleteTeam(id)
{
  const response = await appAPI.delete(`/teams/${id}/`);
  return response.data;
}

export async function getTeamsByMission(id)
{
  const response = await appAPI.get("/teams/?mission_id=" + id);
  return response.data;
}

export async function getPosition(id)
{
  const response = await appAPI.get(`/positions/${id}/`)
  return response.data;
}

export async function getPositionsByUserAndYear(userId, year)
{
  const response = await appAPI.get("/positions/?user_id=" + userId + "&year=" + year )
  return response.data;
}

export async function getOfferedPositions()
{
  const response = await appAPI.get("/positions/?filter_offered&filter_upcoming" )
  return response.data;
}

export async function createPosition(team_id, role_id, optional = false)
{
  var position = {}
  position.team = team_id
  position.role = role_id 
  position.optional = optional

  const response = await appAPI.post("/positions/", position ) 
  return response.data;
}

export async function updatePosition(position)
{
  const response = await appAPI.post("/positions/" + position.id + "/", position) 
  return response.data;
}

export async function deletePosition(id)
{
  const response = await appAPI.delete("/positions/" + id ) 
  return response.data;
}

export async function getPositionActions(id)
{
  const response = await appAPI.get("/positions/" + id + "/actions")
  return response.data;
}

export async function assignPosition(id)
{
  await appAPI.post("/positions/" + id + "/assign")

}

export async function dismissPosition(id)
{
  await appAPI.post("/positions/" + id + "/dismiss")
}

export async function offerPosition(id)
{
  await appAPI.post("/positions/" + id + "/offer")
}

export async function shiftPosition(from_id, to_id)
{ 
  var data = {}
  data.action = "shift"
  data.from_id = from_id
  data.to_id = to_id

  await appAPI.post("/positions/" + from_id + "/shift", data)
}
