<template>
<q-item>
  <q-item-section avatar> 
    <q-avatar icon="person" class="text-grey-10" />
  </q-item-section>
  <q-item-section>
    <q-select v-model="selectedRole" :options="options"
    @update:model-value="update"/>
    <q-toggle v-model="optional" label="optional" 
    @update:model-value="update"/>
  </q-item-section>
  <q-item-section side>
    <q-btn flat round dense
    icon="delete"  class="bg-red text-white" 
    @click="$emit('removePosition',position.id)" />
  </q-item-section>
</q-item>
</template>

<script>

export default {
  props: { position: Object, roles: Array },
  data() {
    return {
      selectedRole: '',
      optional: false
    }
  },
  mounted() {
    this.selectedRole = this.$props.position.role.name
    this.optional = this.$props.position.optional

  },
  computed: {
    options() {
      return this.$props.roles.map( e => e.name )
    }
  },
  methods: {
    update() {
      let pos = {
        id: this.$props.position.id,
        role: this.$props.roles.find(e => e.name === this.selectedRole).id,
        optional: this.optional
      }
      this.$emit('updatePosition', pos)
    }
  }
}
</script>
