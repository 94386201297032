import { createApp } from 'vue'
import { createPinia } from 'pinia'
import { useRegisterSW } from 'virtual:pwa-register/vue'
import { Quasar } from 'quasar'
import quasarUserOptions from './quasar-user-options'
import App from './App.vue'
import router from './router'


const pinia = createPinia()
//const intervalMS = 60 * 60 * 1000
const intervalMS = 10 * 60 * 1000

const updateServiceWorker = useRegisterSW({
  onRegistered(r) {
    r && setInterval(() => {
      r.update()
      console.log("Check updates...")
    }, intervalMS)
  }
})
//import './registerServiceWorker'


createApp(App)
  .use(pinia)
  .use(router)
  .use(Quasar, quasarUserOptions)
  .mount('#app')

