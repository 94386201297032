<template>
<q-item
clickable
v-ripple
:to="'/missions/' + mission.id"
:class="{'assigned':mission.assigned}"
>
  <q-item-section>
    <q-item-label overline>
        {{ start_date }}
    </q-item-label>
  <q-item-label>
    <q-icon
      v-if="mission.is_canceled"
      name="event_busy"
      color="negative"
      class="q-pa-xs"
      >
      <q-tooltip>Der Termin wurde abgesagt</q-tooltip>
    </q-icon>
    {{mission.title}}
  </q-item-label>
  <q-item-label 
    v-if="details"
    caption
    lines="2"
    class="">
    {{ mission.excerpt }}
  </q-item-label>
  </q-item-section>
  <q-item-section side top>
    <q-item-label v-if="mission.is_canceled" caption>
      abgesagt
    </q-item-label>
     <div
      v-if="badges"
      class="q-gutter-xs q-mt-xs">
      <q-badge
      class="q-pa-xs bg-pink"
      v-if="mission.offered_positions > 0 && !mission.is_canceled">
        {{mission.offered_positions}}<q-icon name="swap_horiz" size="14px" />
          <q-tooltip>Es gibt Tauschangebote</q-tooltip>
      </q-badge>
      <q-badge 
      class="q-pa-xs"
      :class="{'bg-orange':mission.urgent,'bg-green':mission.complete}"
      v-if="mission.open_positions > 0 && !mission.is_canceled">
        {{mission.open_positions}}<q-icon name="group" size="14px" />
          <q-tooltip>Es sind noch Positionen unbesetzt</q-tooltip>
      </q-badge>
      <q-badge 
      class="q-pa-xs"
      v-if="mission.open_positions == 0 && !mission.is_canceled">
        &#8203;<q-icon name="done_all" size="14px" />
          <q-tooltip>Der Wachplan ist voll!</q-tooltip>
      </q-badge>
      <q-badge 
      class="q-pa-xs bg-positive"
      v-if="mission.assigned">
        &#8203;<q-icon name="person" size="16px" />
          <q-tooltip>Du bist eingetragen</q-tooltip>
      </q-badge>
        </div>
  </q-item-section>
</q-item>
</template>

<script>

export default {
  props: { 
    mission: Object,
    details: {
      type: Boolean,
      default: false
    },
    badges: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    start_date() {
      let start = new Date(this.mission.start_date)
      let date_options= {weekday:'short',day:'2-digit',month:'2-digit',year:'numeric'}
      return start.toLocaleDateString(undefined, date_options)
    },
    start_date_short() {
      let start = new Date(this.mission.start_date)
      let date_options = {weekday: 'narrow', day:'2-digit',month:'2-digit',year:'numeric'}
      return start.toLocaleDateString(undefined, date_options)
    }
  }
}
</script>

<style sass>
.assigned {
 /* background: var(--q-secondary) */
 /* font-weight: 900; */
 /* color: var(--q-info) */
}
</style>
