<template>
<q-form class="q-gutter-y-md">
  <q-input
      ref="inputRefOldPassword"
      v-model="oldPassword"
      label="Altes Passwort *"
      :type="hideOldPwd ? 'password' : 'text'"
      :rules="[val => !!val || 'Field is required']"
      >
      <template v-slot:before>
        <q-icon name="password"/>
      </template>
      <template v-slot:append>
        <q-icon
            :name="hideOldPwd ? 'visibility_off' : 'visibility'"
            class="cursor-pointer"
            @click="hideOldPwd = !hideOldPwd"
            />
      </template>
  </q-input>
  <q-input
      v-model="newPassword"
      label="Neues Passwort *"
      :type="hideNewPwd ? 'password' : 'text'"
      :rules="[val => !!val || 'Field is required']"
      >
      <template v-slot:before>
        <q-icon name="password"/>
      </template>
      <template v-slot:append>
        <q-icon
            :name="hideNewPwd ? 'visibility_off' : 'visibility'"
            class="cursor-pointer"
            @click="hideNewPwd = !hideNewPwd"
            />
      </template>
  </q-input>
  <q-input
      v-model="newPassword2"
      label="Neues Passwort erneut *"
      :type="hideNewPwd2 ? 'password' : 'text'"
      :rules="[val => val == this.newPassword|| 'Password missmatch: ' + this.newPassword]"
      >
      <template v-slot:before>
        <q-icon name="password"/>
      </template>
      <template v-slot:append>
        <q-icon
            :name="hideNewPwd2 ? 'visibility_off' : 'visibility'"
            class="cursor-pointer"
            @click="hideNewPwd2 = !hideNewPwd2"
            />
      </template>
  </q-input>
  <div class="row q-gutter-md justify-center">
    <q-btn
      @click="updatePassword"
      :disable="!passwordFormValid"
      >
      Update Password
    </q-btn>
    <q-btn
      @click="cancelForm"
        v-if="cancelButton"
      icon="cancel"
      >
      Abbrechen
    </q-btn>
  </div>
</q-form>
</template>

<script>
  import { ref } from 'vue'
  import { Notify } from 'quasar'
  import { updatePassword } from '@/services/Users.js'

  export default {
    props: { cancelButton: Boolean },
    emits: ['updateSuccessful','formCancel'],
    data() {
      return {
        oldPassword: ref(""),
        newPassword: ref(""),
        newPassword2: ref(""),
        hideOldPwd: ref(true),
        hideNewPwd: ref(true),
        hideNewPwd2: ref(true),
      }
    },
    computed: {
      passwordFormValid() {
        return this.oldPassword &&
          this.newPassword && ( this.newPassword == this.newPassword2 )
      }
    },
    methods: {
      updatePassword() {
        updatePassword(this.oldPassword, this.newPassword)
          .then( () => { 
            Notify.create("Passwort erfolgreich geändert!") 
            this.$emit('updateSuccessful')
          })
          .catch( () => { Notify.create("Passwort ändern fehlgeschlagen!") })
      },
      cancelForm() { this.$emit('formCancel') }
    }
  }
</script>
