<<template>
  <q-item >
    <q-item-section avatar >
      <q-icon v-if="darkMode" name="dark_mode" />
      <q-icon v-else name="light_mode" />
    </q-item-section>
      <q-item-section>
      <slot />
      </q-item-section>
      <q-item-section side>
      <q-toggle v-model="appStore.app_settings.dark_mode" />
      </q-item-section>
  </q-item>
</template>

<script>
  import { useAppStore } from '@/stores/application.js'
  import { storeToRefs } from 'pinia'

  export default {
    setup() {
      const appStore = useAppStore()
      const { darkMode } = storeToRefs(appStore)
      return { darkMode, appStore }
    }
  }
</script>
