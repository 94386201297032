<template>
  <q-form
    @submit="authenticate"
    class="row q-pa-md justify-center q-gutter-y-md" 
  >
    <q-input
      class="col-12"
      filled
      v-model="username"
      label="Benutzer *"
      hint="Dein Benutzername"
      lazy-rules
      :rules="[ val => val && val.length > 0 || 'Please type something']"
    />
    <q-input
      class="col-12"
      filled
     :type="hidePwd ? 'password' : 'text'"
      v-model="password"
      label="Password *"
    >
        <template v-slot:append>
          <q-icon
            :name="hidePwd ? 'visibility_off' : 'visibility'"
            class="cursor-pointer"
            @click="hidePwd = !hidePwd"
          />
        </template>
      </q-input>
    <div class="col-12">
      <q-btn label="Login" color="primary" type="submit" />
    </div>
  </q-form>
</template>

<script>
import { ref } from 'vue'
import { login } from '@/services/Authentication.js'
import { storeMeInfo } from '@/services/Authentication.js'
import { storePermissions, storeUserProfile } from '@/services/Users.js'
import { Notify } from 'quasar'

export default {
  data() {
    return {
      hidePwd: ref(true),
      username: '',
      password: ''
    }
  },
  methods: {
    authenticate() {
      login( this.username, this.password )
      .then( async () => {
          await this.fetchUserData()
          this.$router.replace('/')
          })
      .catch( () => {
        this.loginFailed()
      })
    },
    async fetchUserData() {
      await storeMeInfo()
        .then( async () => {
            await storeUserProfile()
            await storePermissions()
            })
    },
    loginFailed() {
      Notify.create( {
        type: 'negative',
        message: 'Anmeldung fehlgeschlagen'
      })
    }
  }
}
</script>

