<template>
  <q-page padding>
    <q-form
        class="col q-gutter-md"
        >
        <q-input
            label="Name *"
            :rules="[val => !!val || 'Field is required']"
            v-model="mission.title"
            />
      <q-input
          label="Kurzbeschreibung"
          v-model="mission.excerpt"
          />
        <div>
          <div class="text-h6">Beschreibung</div>
          <q-editor
              filled
              placeholder="Füge hier den Beschreibungstext ein..."
              type="textarea"
              label="Beschreibung *"
              :rules="[val => !!val || 'Field is required']"
              v-model="mission.description"
              />
        </div>
        <div class="col-6 col-md-4">
          <q-input
              hint="Startdatum *"
              type="date"
              :rules="[val => !!val || 'Field is required']"
              v-model="mission.start_date"
              />
        </div>
        <div class="col-6 col-md-4">
          <q-input
              hint="Enddatum *"
              type="date"
              :rules="[val => !!val || 'Field is required']"
              v-model="mission.end_date"
              />
        </div>
        <q-btn
            color="primary"
            label="Termin erstellen"
            icon="event"
            @click="create"
            />
    </q-form>
  </q-page>
</template>

<script>
  import { createMission } from '@/services/Missions.js'
  import { Notify } from 'quasar'
  export default {
    data() { 
      return {
        mission: {
          title: "",
          excerpt: "",
          description: "",
          start_date: "",
          end_date: "",
          team_set: []
        }
      }
    }, 
    watch: {
      'mission.start_date': function (newDate, oldDate) {
        if(this.mission.end_date == "") {
          this.mission.end_date = newDate
        }
      }
    },
    methods: {
      create() {
        createMission(this.mission)
          .then( response => {
            Notify.create( { 
                message: "Termin angelegt",
                type: "positive"
              } )
            this.$router.replace( '/missions/' + response.id)
          } )
      }
    }
  }
</script>
