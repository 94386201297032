<template>
  <q-dialog>
    <q-card :flat="$q.dark.mode">
      <q-card-section>
        <cropper
          class="cropper"
          :src="img"
          ref="cropper"
          :stencil-props="{
            aspectRatio: 1/1
            }"
          :canvas="{
            minWidth: 100,
            minHeight: 100,
            maxWidth: 500,
            maxHeight: 500,
            }"
          @change="change"
        />
      </q-card-section>
      <q-card-actions class="row q-gutter-md justify-center items-center">
        <q-file
            v-model="file"
            label="Bild wählen"
            accept="image/*"
        />
        <q-btn 
        round
        v-close-popup
        icon="done"
        color="primary"
        v-if="img"
        @click="crop"
        />
        <q-btn
        round
        v-close-popup
        color="red"
        icon="close"
        @click="reset"
        />
      </q-card-actions>
    </q-card>
    </q-dialog>
</template>

<script>
import 'vue-advanced-cropper/dist/style.css'
import { Cropper } from 'vue-advanced-cropper'
import { updateAvatar } from '@/services/Users.js'
import { Notify } from 'quasar'

export default {
	components: {
		Cropper
	},
	data() {
    return {
      img: null,
      file: null
    }
	},
  watch: {
    file(newFile, oldFile) {
      if(oldFile)
        URL.revokeObjectURL(oldFile)
      if(newFile) {
        const blob = URL.createObjectURL(newFile)
        this.img = blob
      }
    }
  },
	methods: {
    crop() {
			const { canvas } = this.$refs.cropper.getResult();
			canvas.toBlob((blob) => {
        updateAvatar(blob).then( () => { Notify.create("Update erfolgreich!") })
			}, this.img.type);
		},
		reset() {
			this.img = null
      this.file = null
			},
		change({ coordinates, canvas }) {
			console.log(coordinates, canvas)
		}
	}
}
</script>
 
<style>
.cropper {
    height: 450px;
    width: 500px;
    background: #ddd;
  }
</style> 
