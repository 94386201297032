<template>
  <div id="avatar-section" class="row justify-center settings-section q-mt-lg q-mx-auto q-pa-md">
    <q-avatar size="200px">
      <q-img :src="userStore.profile.avatar" />
        <q-btn round icon="edit" color="primary" text-color="white"  class="absolute-bottom-right" @click="$emit('editButtonClicked')" />
    </q-avatar>
  </div>
</template>

<script>
import { useUserStore } from '@/stores/user.js'

export default {
  emits: ["editButtonClicked"],
  setup() {
    const userStore = useUserStore()
    return {
      userStore
    }
  }
}


</script>
