import { appAPI } from '@/api'
import { authAPI } from '@/api'

import { useUserStore } from '@/stores/user.js'


export async function getUsers()
{ 
  const response = await appAPI.get("/users/");
  return response.data;
}

export async function getUser( id )
{ 
  const response = await appAPI.get(`/users/${id}/`);
  return response.data;
}

export async function getProfiles()
{ 
  const response = await appAPI.get("/profiles/");
  return response.data;
}

export async function getProfile( id )
{ 
  const response = await appAPI.get(`/profiles/${id}/`);
  return response.data;
}

export async function updateProfile()
{ 
  const userStore = useUserStore()
  let profile = {}
  profile.address = userStore.profile.address
  profile.phone = userStore.profile.phone
  const response = await appAPI.patch(`/profiles/${userStore.data.id}/`, profile );
  userStore.profile = response.data
}

export async function getMembers()
{ 
  const response = await appAPI.get("/members/");
  return response.data;
}

export async function getRoles()
{ 
  const response = await appAPI.get("/roles/");
  return response.data;
}

export async function getMemberRoles( id )
{ 
  const response = await appAPI.get(`/members/${id}/roles/`);
  return response.data;
}

export async function setMemberRoles( id, roles )
{ 
  const response = await appAPI.post(`/members/${id}/roles/`, { 'roles': roles });
  return response.data;
}

export async function getUserData()
{ 
  const response = await authAPI.get("/users/me");
  return response.data;
}

export async function updatePassword(currentPass,newPass)
{ 
  const response = await authAPI.post("/users/set_password/", {
  'current_password': currentPass,
  'new_password': newPass
  })
  return response.data;
}

export async function storePermissions()
{ 
  const  userStore = useUserStore()
  const response = await appAPI.get("/permissions/");
  userStore.permissions = response.data
}

export async function storeUserProfile()
{ 
  const userStore = useUserStore()
  const response = await appAPI.get(`/profiles/${userStore.data.id}/`);
  userStore.profile = response.data
}

export async function updateAvatar(avatar)
{ 
  const userStore = useUserStore()
  const form = new FormData()
  form.append('avatar', avatar, 'new_avatar.png')
  const response = await appAPI.patch(`/profiles/${userStore.data.id}/`, form );
  userStore.profile = response.data
}

export function hasPermission(permission)
{
  const  userStore = useUserStore()
  return userStore.permissions.includes(permission)
}
