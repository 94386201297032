import { authAPI } from '@/api'
import { useUserStore } from '@/stores/user.js'


export async function login(username, password)
{ 
  const response = await authAPI.post('/token/login/', {
    username: username,
    password: password
  })
  const userStore = useUserStore()
  userStore.token = response.data.auth_token
}

export async function logout()
{ 
  const userStore = useUserStore()
  const response = await authAPI.post('/token/logout/',{
    data: userStore.token,
  })
  userStore.token = ""
  return response
}

export async function storeMeInfo()
{ 
  const response = await authAPI.get("/users/me/")
  const userStore = useUserStore()
  userStore.data = response.data
}


export async function storeMeProfile()
{ 
  const response = await authAPI.get("/users/me/")
  const userStore = useUserStore()
  userStore.profile = response.data
}
