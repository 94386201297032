<template>
  <q-header
      v-if="userStore.authenticated"
      >
    <q-toolbar
       class="text-white bg-primary">
      <q-btn
      v-if="$route.meta.toolbarType == 'navback'"
      flat 
      icon="arrow_back" 
      @click="$router.back()" 
      />
      <q-btn
      v-if="!$route.meta.toolbarType"
      flat round dense
      @click="$emit('menuButtonClicked')"
      icon="menu" class="q-mr-sm lt-md" />
      <q-toolbar-title>
          <span class="lt-md">{{ title }}</span>
          <q-img id="logo" class="gt-sm" src="@/assets/images/textlogo.png" width="200px"/>
      </q-toolbar-title>
      <q-tabs class="gt-sm">
        <q-route-tab  label="Dashboard" to="/" />
        <q-route-tab  label="Wachplan" to="/missions" />
        <q-route-tab  label="Mitglieder" to="/members" />
        <q-route-tab  label="Einstellungen" to="/settings" />
      </q-tabs>
      <q-avatar>
        <img
        :src="userStore.profile.avatar"
        style="object-fit: cover;"
        class="gt-sm"
        />
      </q-avatar>
      <q-btn 
        flat round
        to="/logout"
        icon="logout"
        class="gt-sm"
        />
    </q-toolbar>
    <ReloadPrompt />
  </q-header>
</template>

<script>
import update from '@/mixins/update.js'
import ReloadPrompt from '@/components/ReloadPrompt.vue'
import { useUserStore } from '@/stores/user.js'
import { useAppStore } from '@/stores/application.js'

export default {
  components: {
    ReloadPrompt
  },
  setup() {
    const userStore = useUserStore()
    const appStore = useAppStore()
    return {
      userStore,
      appStore
    }
  },
  mixins: [update],
  computed: {
    title() {
      return ( this.$route.meta.toolbarTitle ? this.$route.meta.toolbarTitle : '' )
    }
  },
  methods: {
    cancel() {
        this.appStore.cancelAction()
    }
  }
}
</script>

<style scoped>
#logo {
    filter: invert() brightness(9);
}
</style>
