<template>
  <q-drawer
  v-if="userStore.authenticated"
  v-model="drawerOpen"
  :width="300"
  :breakpoint="500"
  :elevated="!$q.dark.mode"
  behavior="mobile"
  >
  <div class="sidenav-header q-py-lg">
    <div class="col justify-start q-gutter-md q-pl-md">
      <div class="col-3 row justify-start">
        <q-avatar size="6em">
          <q-img
          :src="userStore.profile.avatar"
          />
        </q-avatar>
      </div>
      <div class="col-7 row items-center">
        <div class="col-12 text-h5">
          {{ userStore.fullName }}
        </div>
        <div class="col-12 text-subtitle2 ellipsis">
          {{ userStore.data.email }}
        </div>
      </div>
    </div>
  </div>
  <q-separator inset />
    <q-list padding class="menu-list">
        <q-item clickable v-ripple @click="$router.replace('/')">
        <q-item-section avatar >
          <q-icon name="dashboard" />
        </q-item-section>
        <q-item-section>
          <q-item-label>Dashboard</q-item-label>
        </q-item-section>
      </q-item>
      <q-item clickable v-ripple @click="$router.replace('/missions')">
        <q-item-section avatar >
          <q-icon name="support" />
        </q-item-section>
        <q-item-section>
          <q-item-label>Wachplan</q-item-label>
        </q-item-section>
      </q-item>
      <q-item clickable v-ripple @click="$router.replace('/members')">
        <q-item-section avatar >
          <q-icon name="group" />
        </q-item-section>
        <q-item-section>
          <q-item-label>Mitglieder</q-item-label>
        </q-item-section>
      </q-item>
      <q-item clickable v-ripple @click="$router.replace('/settings')">
        <q-item-section avatar >
          <q-icon name="settings" />
        </q-item-section>
        <q-item-section>
          <q-item-label>Einstellungen</q-item-label>
        </q-item-section>
      </q-item>
      <q-item clickable v-ripple @click="$router.replace('/logout')">
        <q-item-section avatar >
          <q-icon name="logout" />
        </q-item-section>
        <q-item-section>
          <q-item-label>Logout</q-item-label>
        </q-item-section>
      </q-item>
    </q-list>
    <div class="absolute-bottom q-pa-sm">
        <div class="text-grey text-weight-light text-center">V1.2.0</div>
        <div class="text-grey text-weight-light text-center">Made with <q-icon name="favorite" /> by Bernhard Rohloff</div>
    </div>
  </q-drawer>
</template>

<script>
import DarkModeToggle from '@/components/DarkModeToggle.vue'
import { useUserStore } from '@/stores/user.js'
export default {
  name: "SidebarNav",
  setup() {
    const userStore = useUserStore()
    return {
      userStore
    }
  },
  data() {
    return {
      drawerOpen: false,
    }
  },
  methods: {
    toggleSideNavigation() {
      this.drawerOpen = !this.drawerOpen
    }
  }
}
</script>

<style lang="scss">
.sidenav-header {
  height: 200px;
}
</style>
