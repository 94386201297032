<template>
  <q-page padding>
    <div
        v-if="loadingMission"
        class="col items-center justify-center q-pa-lg q-gutter-md"
        >
        <div class="row justify-center">
          <q-spinner size="3em"  color="primary" />
        </div>
    </div>
    <q-form 
      v-else
      class="col q-gutter-md"
      >
      <q-input
          label="Name *"
          :rules="[val => !!val || 'Field is required']"
          v-model="mission.title"
          />
      <q-input
          label="Kurzbeschreibung"
          v-model="mission.excerpt"
          />
      <div>
        <div class="text-h6">Beschreibung</div>
        <q-editor
            filled
            placeholder="Füge hier den Beschreibungstext ein..."
            type="textarea"
            label="Beschreibung *"
            :rules="[val => !!val || 'Field is required']"
            v-model="mission.description"
            />
      </div>
      <div class="col-6 col-md-4">
        <q-input
            title="Startdatum *"
            type="date"
            :rules="[val => !!val || 'Field is required']"
            v-model="mission.start_date"
            />
      </div>
      <div class="col-6 col-md-4">
        <q-input
            title="Enddatum *"
            type="date"
            :rules="[val => !!val || 'Field is required']"
            v-model="mission.end_date"
            />
      </div>
      <div class="col-6 col-md-4">
        <q-checkbox
            :label="'Teams (' + mission.team_set.length + ') kopieren'"
            v-model="copyTeams"
            v-if="mission.team_set.length"
            />
      </div>
      <q-btn
          color="primary"
          label="Termin kopieren"
          icon="copy_all"
          @click="copy"
          />
    </q-form>
  </q-page>
</template>

<script>
  import { getMission, createMission } from '@/services/Missions.js'
  import { getTeamsByMission, createTeam, createPosition } from '@/services/Teams.js'
  import { Notify } from 'quasar'

  export default {
    data() { 
      return {
        mission: {
          title: "",
          excerpt: "",
          description: "",
          start_date: "",
          end_date: "",
          team_set: []
        },
        teams: [],
        copyTeams: true,
        loadingMission: true
      } 
    },
    mounted() {
       getMission( this.$route.params.id )
        .then( response => { 
          this.mission = response 
          if (this.mission.team_set.length)
            getTeamsByMission( this.$route.params.id )
              .then( response => this.teams = response )
            
          this.loadingMission = false
        })
    },
    methods: {
      async copy() {
        let mission = this.mission
        mission.team_set = []
        // await mission creation
        await createMission(mission)
          .then( response => mission = response )
        // copy any existing teams
        let proms = []
        if ( this.copyTeams && this.teams.length ) {
          this.teams.forEach( (team) => { 
            proms.push( this.copyTeam(team, mission.id) )
          })
        }
        Promise.all(proms).then( () => {
          Notify.create( {
            message: "Termin kopiert",
            type: "positive"
          } )
          this.switchToMission(mission.id)
        })
      },
      async copyTeam(team, missionId) {
        let newTeam
        await createTeam(team.name, missionId)
          .then( response => newTeam = response )
        let proms = []
        team.positions.forEach( (position) => {
          proms.push(createPosition( newTeam.id, position.role.id, position.optional ))
        })
        await Promise.all(proms).then( () => console.log("Positionen erstellt") )
      },
      switchToMission(id) {
          this.$router.back()
          this.$router.replace('/missions/' + id)
      }
    }
  }
</script>
