<template>
  <q-page padding>
    <div class="row justify-center q-gutter-md q-pa-md">
      <div class="col-12 col-md-6 col-lg-3" v-if="false">
        <q-card class="bg-blue-3" >
          <q-card-section>
            <div class="text-h6">
              <q-avatar><q-icon name="photo_camera"/></q-avatar>Webcam
            </div>
          </q-card-section>
          <q-card-section>
            <div>Webcam image here</div>
          </q-card-section>
        </q-card>
      </div>
      <div class="col-12 col-md-6 col-lg-3">
      <q-card :flat="$q.dark.mode">
        <q-card-section>
          <div class="text-h6">
            <q-avatar><q-icon name="sunny"/></q-avatar>Wetter
          </div>
          <TemperatureItem sensorId="laws" />
        </q-card-section>
      </q-card>
      </div>
      <div class="col-12 col-md-6 col-lg-3">
      <q-card :flat="$q.dark.mode">
        <q-card-section>
          <div class="text-h6">
          <q-avatar><q-icon name="support"/></q-avatar>Nächste Termine
          </div>
          <q-list separator>
            <q-item v-if="loadingData">
              <q-item-section class="row justify-center">
                <q-spinner class="col" size="25" color="primary" />
              </q-item-section>
            </q-item>
            <template v-for="mission in missions" :key="mission" >
              <mission-list-item  :mission="mission" badges  />
            </template>
          </q-list>
        </q-card-section>
      </q-card>
      </div>
      <div class="col-12 col-md-6 col-lg-3">
      <q-card :flat="$q.dark.mode">
        <q-card-section>
          <div class="text-h6">
            <q-avatar><q-icon name="swap_horiz"/></q-avatar>Tauschangebote
          </div>
          <q-list separator>
            <q-item v-if="loadingData">
              <q-item-section class="row justify-center">
                <q-spinner class="col" size="25" color="primary" />
              </q-item-section>
            </q-item>
            <template v-for="offer in offers" :key="offer" >
              <PositionOfferListItem  :pos="offer"/>
            </template>
            <q-item dense v-if="!offers.length">
              <q-item-section>
              <q-item-label>Momentan gibt es keine Tauschangebote.</q-item-label>
              </q-item-section>
            </q-item>
          </q-list>
        </q-card-section>
      </q-card>
      </div>
    </div>
  </q-page>
</template>

<script>
import { useUserStore } from '@/stores/user.js'
import { getMissionsUpcoming } from '@/services/Missions.js'
import { getOfferedPositions } from '@/services/Teams.js'
import MissionListItem from '@/components/MissionListItem.vue'
import PositionOfferListItem from '@/components/PositionOfferListItem.vue'
import TemperatureItem from '@/components/TemperatureItem.vue'


export default {
  setup() {
    const userStore = useUserStore()
    return { userStore }
  },
  data() {
    return {
      missions: [],
      offers: [],
      loadingData: false
    }
  },
  components: {
    'mission-list-item': MissionListItem,
    PositionOfferListItem,
    TemperatureItem
    },
  mounted() {
    getMissionsUpcoming(5)
      .then( data => {
        this.missions = data.sort(this.by_date)
          this.loadingData=false
        })
    getOfferedPositions()
      .then( data => {
        this.offers = data
          this.loadingData=false
        })

  },
  methods: {
    by_date(a, b){
      return new Date(a.start_date).valueOf() - new Date(b.start_date).valueOf()
    },
  }
}
</script>

<style>
#title-block {
  height: 25vh;
  max-height: 300px;
}
</style>
