<template>
  <q-page padding>
    <div class="row">
      <div class="col">
        <q-input 
        clearable
        v-model="filter"
        >
        <template v-slot:prepend>
              <q-icon name="search" />
            </template>
        </q-input>
      </div>
    </div>
    <div class="row">
      <div class="col">
        <q-table
          :rows='rows'
          :columns='columns'
          :loading='loading'
          :rows-per-page-options=[0]
          :filter="filter"
          :filter-method="filter_members"
          :flat="$q.dark.mode"
          grid
          grid-header
          @row-click="show_alert"
        />
      </div>
    </div>
    <q-page-scroller position="bottom-right" :scroll-offset="150" :offset="[18, 18]">
      <q-btn fab icon="keyboard_arrow_up" color="primary" />
    </q-page-scroller>
  </q-page>
</template>

<script>
import { getMembers } from '@/services/Users.js'
export default {
  data() {
    return {
      loading:true,
      filter:'',
      columns: [
        { name:'name', label:'Name', field:'name', align:'left', sortable:true, style:'width: 500px'},
        { name:'missions_count', label:'Wachdienste', field:'missions_count', sortable:true}
        ],
      rows: [],
    }
  },
  mounted() {
    getMembers().then( 
      response => {
        this.rows = response
        this.loading = false
        }
      )
  },
  methods: {
    filter_members() {
     let rex = new RegExp(this.filter, 'i')
     return this.rows.filter((m) => {
         return m.name.match(rex)
     })
    },
    // eslint-disable-next-line no-unused-vars
    show_alert(evt, row, index) {
      this.$router.push("/profiles/" + row.id)
    }
  }
}
  </script>
