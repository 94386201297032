<template>
  <q-page padding>
    <q-toolbar v-if="!$route.meta.hideFilters" class="text-primary q-mt-md q-gutter-md">
      <q-btn-dropdown
        push
        icon="filter_alt"
        color="primary"
        class="ellipsis"
        label="filter"
        >
        <q-list>
        <template v-for="filter in filters" :key="filter">
          <q-item v-if="filter.selectable" clickable v-close-popup @click="filter.filter">
            <q-item-section>
              <q-item-label>{{filter.name}}</q-item-label>
            </q-item-section>
          </q-item>
        </template>
        </q-list>
      </q-btn-dropdown>
      <q-btn
      push
      class="q-mr-sm bg-primary text-white"
      to="/missions/new"
      v-if="canAddMission"
      label="Neuer Termin"
      icon="event"
      />
    </q-toolbar>
    <div class="col items-center">
      <div v-if="!$route.meta.hideFilters" class="text-h6 q-py-md">
        <q-icon name="filter_alt" />{{ filters[currentFilter].name }} ({{ missions.length }})
      </div>
      <q-list separator>
        <q-item v-if="loadingData">
          <q-item-section class="row justify-center">
            <q-spinner class="col" size="25" color="primary" />
          </q-item-section>
        </q-item>
        <q-item v-if="!missions.length && !loadingData">
          <q-item-section>
            <div class="text-h6 text-primary text-center">Keine passenden Einträge gefunden.</div>
          </q-item-section>
        </q-item>
        <template v-for="mission in missions" :key="mission" >
          <mission-list-item :mission="mission" details badges />
        </template>
      </q-list>
    <q-toolbar v-if="$route.meta.showCancel" class="text-primary q-mt-md q-gutter-md fixed-bottom q-py-md">
        <q-btn
        label="Abbrechen"
        icon="cancel"
        color="primary"
        push
        @click="cancel"
        />
        </q-toolbar>
    </div>
    <q-page-scroller position="bottom-right" :scroll-offset="150" :offset="[18, 18]">
      <q-btn fab icon="keyboard_arrow_up" color="primary" />
    </q-page-scroller>
  </q-page>
</template>

<script>
import MissionListItem from '@/components/MissionListItem.vue'
import { hasPermission } from '@/services/Users.js'
import { 
  getMissionsUpcoming,
  getMissionsByYear,
  getMissionsByYearAndMember,
  getShiftableMissionsForMember,
  createMission
  } from '@/services/Missions.js'

import { useUserStore } from '@/stores/user.js'
import { useAppStore } from '@/stores/application.js'

export default {
  setup() {
    const userStore = useUserStore()
    const appStore = useAppStore()
    return {
      userStore,
      appStore
    }
  },
  components: {
    'mission-list-item': MissionListItem
    },
  data() {
    return {
        missions: [],
        filters: [
          { name: "Zukünftige Termine", filter: this.filter_upcoming, selectable: true },
          { name: "Alle Termine", filter: this.filter_all, selectable: true },
          { name: "Meine Termine", filter: this.filter_my, selectable: true },
          { name: "Tauschtermine", filter: this.filter_shiftable, selectable: false }
        ],
        currentFilter: 0,
        showCreateForm: false,
        loadingData: false
    }
  },
  mounted(){
    this.missions = this.appStore.missionData
    if(!this.missions.length || this.$route.meta.filterIndex) {
      this.fetch_filtered()
    }
  },
  computed: {
    canAddMission() {
      return hasPermission("missions.add_mission")
    },
  },
  methods: {
      fetch_filtered() {
      if(this.$route.meta.filterIndex) {
        this.filters[this.$route.meta.filterIndex].filter() 
      }
      else {
        let index = this.appStore.missionFilter || this.currentFilter
        this.filters[index].filter() 
      }
    },
    filter_my() {
      this.currentFilter=2
      this.appStore.app_state.mission_filter=2; 
      this.loadingData=true

      getMissionsByYearAndMember(new Date().getFullYear(), this.userStore.data.id)
        .then( data => {
          this.missions = data.sort(this.by_date)
          this.appStore.app_state.mission_data = this.missions
          })
        .finally(() => {
          this.loadingData = false
        })
    },
    filter_all() {
      this.currentFilter=1
      this.appStore.app_state.mission_filter=1; 
      this.loadingData=true

      getMissionsByYear(new Date().getFullYear())
        .then( data => { 
          this.missions = data.sort(this.by_date)
          this.appStore.app_state.mission_data = this.missions
          })
        .finally(() => {
          this.loadingData = false
        })
    },
    filter_upcoming() {
      this.currentFilter=0
      this.appStore.app_state.mission_filter=0; 
      this.loadingData=true

      getMissionsUpcoming()
      .then( data => {
          this.missions = data.sort(this.by_date)
          this.appStore.app_state.mission_data = this.missions
        })
        .finally(() => {
          this.loadingData = false
        })
    },
    filter_shiftable() {
        getShiftableMissionsForMember(this.userStore.data.id)
           .then( data => {
             this.missions = data.sort(this.by_date)
             this.appStore.app_state.mission_data = this.missions
           })
          .finally(() => {
            this.loadingData = false
          })
    },
    cancel() {
        this.appStore.clearAction()
        this.$router.back()
        console.log("cancel clicked!")

    },
    by_date(a, b){
      return new Date(a.start_date).valueOf() - new Date(b.start_date).valueOf()
    },
    createMission(mission) {
        createMission(mission)
        .then( response => this.$router.replace('/missions/' + response.id))
    }
  }
}
</script>

<style>
.breadcrumbs {
  font-size: 16px;
}
#mission-filter-label {
  width: 15ch;
  overflow: ellipsis;
}
</style>
