import { defineStore } from "pinia"
import { useStorage } from "@vueuse/core"

export const useAppStore = defineStore( "AppStore", {

  id: 'user',
  state: () => { 
    return {
      action_state: {
        action: "",
        action_item: {},
        action_cancel: false
      },
      app_state: {
        mission_filter: null,
        mission_data: []
      },
      app_settings: {
        dark_mode: useStorage("dark-mode", false)
      }
    }
  },
  // getters
  actions: {
    clear() {
      this.action_state= {
        action: "",
        action_item: {}
      }
      this.app_state= {
        mission_filter: "",
      }
    },
    clearAction() {
      this.action_state= {
        action: "",
        action_item: {}
      }
    },
    cancelAction() {
       this.action_state.action_cancel = true
       console.log("cancel action")
    },
    clearMissionData() {
      this.app_state.mission_data = []
    }
  },
  getters: {
    action() {
      return this.action_state.action
    },
    actionItem() {
      return this.action_state.action_item
    },
    actionCanceled() {
      return this.action_state.action_cancel
    },
    missionFilter() {
      return this.app_state.mission_filter
    },
    missionData() {
      return this.app_state.mission_data
    },
    darkMode() {
      return this.app_settings.dark_mode
    }
  }
})
