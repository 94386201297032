<template>
    <q-item
    clickable
    v-ripple
    class="q-pa-md"
    :to="route"
    >
      <q-item-section avatar><q-icon :name="icon"/></q-item-section>
      <q-item-section><slot /></q-item-section>
    </q-item>
</template>

<script>
export default {
  props: {
    icon: String,
    route: String
  }
}
</script>
