import { appAPI } from '@/api'



export async function getMissions()
{
  const response = await appAPI.get("/missions/");
  return response.data;
}

export async function getMission(id)
{
  const response = await appAPI.get(`/missions/${id}/`);
  return response.data;
}

export async function getMissionsUpcoming(limit=0)
{
  const response = await appAPI.get(
    "/missions/?filter_upcoming" +
    ((limit > 0) ? `&limit=${limit}` : '')
  );
  return response.data;
}

export async function getShiftableMissionsForMember(member)
{
  const response = await appAPI.get("/missions/?filter_upcoming&filter_exclude_member=" + member);
  return response.data;
}

export async function getMissionsByYear(year)
{
  const response = await appAPI.get("/missions/?filter_year=" + year);
  return response.data;
}

export async function getMissionsByDateRange(start, end)
{
  const response = await appAPI.get("/missions/?filter_range=" + start + ":" + end);
  return response.data;
}


export async function getMissionsByMember(member)
{
  const response = await appAPI.get("/missions/?filter_member=" + member);
  return response.data;
}

export async function getMissionsByYearAndMember(year, member)
{
  const response = 
    await appAPI.get("/missions/?filter_year=" + year + "&filter_member=" + member);
  return response.data;
}

export async function createMission(mission)
{
  const response = await appAPI.post("/missions/", mission);
  return response.data;
}

export async function updateMission(mission, id)
{
  const response = await appAPI.post("/missions/" + id + "/", mission);
  return response.data;
}

export async function deleteMission(id)
{
  const response = await appAPI.delete("/missions/" + id + "/");
  return response.data;
}

export async function cancelMission(id)
{
  const response = await appAPI.post("/missions/" + id + "/cancel");
  return response.data;
}

export async function reopenMission(id)
{
  const response = await appAPI.post("/missions/" + id + "/reopen");
  return response.data;
}
