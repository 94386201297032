import axios from 'axios'
import router from '@/router/index.js'
import { useUserStore } from '@/stores/user.js'

const appAPI = new axios.create({
  baseURL: `${import.meta.env.VITE_SERVICE_API_URL}/api/v1/app`,
  timeout: Number(import.meta.env.VITE_API_TIMEOUT),
  transformRequest: [ (data, headers) => {
    const userStore = useUserStore()
    headers['Authorization'] = 'Token ' + userStore.token
    return data
  }, ...axios.defaults.transformRequest ]
})

const authAPI = new axios.create({
  baseURL: `${import.meta.env.VITE_SERVICE_API_URL}/api/v1/auth`,
  timeout: Number(import.meta.env.VITE_API_TIMEOUT),
  transformRequest: [(data, headers) => {
    const userStore = useUserStore()
    if( userStore.authenticated ) {
      headers['Authorization'] = 'Token ' + userStore.token
    }
    return data
  }, ...axios.defaults.transformRequest ]
})

const sensorsAPI = new axios.create({
  baseURL: `${import.meta.env.VITE_SENSORS_API_URL}/json`,
  timeout: Number(import.meta.env.VITE_API_TIMEOUT)
})

appAPI.interceptors.response.use( (response) => {
  return response
}, (error) => {
  if( 401 === error.response.status ) {
    const userStore = useUserStore()
    userStore.clear()
    router.push("/")
  } else {
    Promise.reject(error)
  }
})

authAPI.interceptors.response.use( (response) => {
  return response
}, (error) => {
  if( error.message === "TIMEOUT" ){
    console.log("AXIOS request timeout")
    return
  }
  if( 401 === error.response.status ) {
    const userStore = useUserStore()
    userStore.clear()
    router.push("/")
  } else {
    console.log(error.toJSON())
    Promise.reject(error)
  }
})

export { appAPI, authAPI, sensorsAPI }
