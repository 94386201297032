<template>
<q-item
clickable
v-ripple
:to="'/missions/' + pos.mission_id"
>
  <q-item-section avatar> 
    <ProfileAvatar :id="pos.member.id" />
  </q-item-section>
  <q-item-section>
    <q-item-label>{{ pos.role.name}}</q-item-label>
    <q-item-label caption lines="1">{{ pos.mission_title }} - {{ mission_date }}</q-item-label>
  </q-item-section>
  <q-item-section avatar side>
    <q-avatar>
      <q-icon name="navigate_next" />
    </q-avatar>
  </q-item-section>
</q-item>
</template>

<script>
import ProfileAvatar from '@/components/ProfileAvatar.vue'

export default {
  components: {
    ProfileAvatar
  },
  props: { 
    pos: Object,
    },
  data() {
    return {
      date_options: {weekday:'short',day:'2-digit',month:'2-digit',year:'numeric'}
    }
  },
  computed: {
    mission_date() {
      let start = new Date(this.pos.mission_date)
      return start.toLocaleDateString(undefined,this.date_options)
    }
  }
}
</script>

<style sass>
</style>
