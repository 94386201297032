<template>
  <q-btn round :to="`/profiles/${id}`">
    <q-avatar :size="size">
      <q-img  
      :src="avatar"
      />
    </q-avatar>
  </q-btn>
</template>

<script>
import { getProfile } from '@/services/Users.js'
export default {
  props: {
    id: Number,
    size: {
      type: String,
      default: '50px'
    }
  },
  data() {
    return {
      avatar: ''
    }
  },
  mounted() {
    this.updateAvatar()
  },
  watch: {
    id() {
      this.updateAvatar()
    }
  },
  methods: {
    updateAvatar() {
      getProfile(this.id)
      .then( response => { this.avatar = response.avatar })
    }
  }
}
</script>
