<template>
 <q-page padding>
    <div class="col q-gutter-md">
      <q-card :flat="$q.dark.mode">
        <q-card-section>
          Hier kannst du die Informationen bearbeiten, welche in deinem <router-link class="text-primary" clickable :to="'/profiles/' + userStore.data.id">Benutzerprofil</router-link> erscheinen.
          Die Felder, welche du leer lässt, werden nicht auf deinem Profil angezeigt. Das Profilbild ist auf mehreren Ansichten innerhalb des Wachplans zu sehen.
        </q-card-section>
      </q-card>
      <avatar-settings-widget @editButtonClicked="showAvatarDialog" />
        <div class="text-h6">
          Persönliche Angaben
        </div>
        <q-input
          label="Adresse"
          v-model="address"
          />
        <q-input
          label="Telefon/Mobil"
          v-model="phone"
          />
        <q-btn
          icon="save"
          color="primary" 
          label="Speichern" 
          @click="saveProfile" />
    </div>
  </q-page>
</template>

<script>
import AvatarSettingsWidget from '@/components/AvatarSettingsWidget.vue'
import AvatarSettingsDialog from '@/components/AvatarSettingsDialog.vue'

import { useUserStore } from '@/stores/user.js'
import { updateProfile } from '@/services/Users.js'
import { Dialog } from 'quasar'
import { Notify } from 'quasar'

export default {
  components: {
    "avatar-settings-widget": AvatarSettingsWidget,
  },
  setup() {
    const userStore = useUserStore()
    return { userStore }
  },
  data() {
    return {
      address: "",
      phone: ""
    }
  },
  created() {
    this.address = this.userStore.profile.address
    this.phone = this.userStore.profile.phone
  },
  methods: {
    showAvatarDialog() {
      Dialog.create({
        component: AvatarSettingsDialog
      })
    },
    saveProfile() {
      this.userStore.profile.address = this.address
      this.userStore.profile.phone = this.phone
      updateProfile()
        .then( () => Notify.create({
          message:"Profil gespeichert",
          type:"positive"
        }) )
    }
  }
}
</script>
