<template>
  <q-page padding>
    <div class="col q-gutter-md">
      <q-card :flat="$q.dark.mode">
        <q-card-section>
          Hier kannst du deine Rollen anpassen. Wenn du eine Ausbildung für eine bestimmte Rolle noch nicht hast,
          kann es Sinn machen, diese Rolle für dich zu deaktivieren. Du kannst dann keine
          neuen Positionen mit dieser Rolle annehmen.
        </q-card-section>
      </q-card>
        <roles-list />
    </div>
  </q-page>
</template>

<script>
  import RolesList from "@/components/RolesList.vue"
  export default {
    components: { RolesList }
  }
</script>
