<template>
<q-layout view="hHh Lpr fFf">
  <!-- header section -->
  <MainHeader @menu-button-clicked="toggleSideNav" />
  <SidebarNav ref="sidenav" />
  <!-- main section -->
  <q-page-container>
      <router-view/>
  </q-page-container>
</q-layout>
</template>

<script>
import SidebarNav from '@/components/SidebarNav.vue'
import MainHeader from '@/components/MainHeader.vue'
import { useAppStore } from "@/stores/application.js"
import { storeToRefs } from 'pinia'

export default {
  components: {
    SidebarNav,
    MainHeader
  },
  setup() {
    const appStore = useAppStore()
    const { darkMode } = storeToRefs(appStore)
    return {
      darkMode
    }
  },
    mounted() {
            this.$q.dark.set(this.darkMode)
    },
    watch: {
        darkMode(){
            this.$q.dark.set(this.darkMode)
        }
    },
  methods: {
    toggleSideNav(){
      this.$refs.sidenav.toggleSideNavigation()
    }
  }
}
</script>


<style lang="scss">
body.body--dark {
    color: #f0f0f0;
}
body.body--light {
    background: #f5fbff;
    color: #0f0f0f;
}
.q-menu--dark {
    background: #2c3c57 !important;
    box-shadow: none !important;
}
</style>
