
<template>
<div class="container">
  <div class="row">
    <div class="col s12">
      You have successfully logged out.
    </div>
  </div>
</div>
</template>


<script>
import { logout } from '@/services/Authentication.js'
import { useUserStore } from '@/stores/user.js'


export default {
  setup() {
    const userStore = useUserStore()
    return {
      userStore
    }
  },
  mounted() {
    logout()
    .then( () => {
        this.userStore.clear()
        this.$router.push('/login')
        })
    .catch( error => {
        this.userStore.clear()
        this.$router.push('/login')
        console.log(error)
    })
  }
}
</script>
